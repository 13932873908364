export default defineNuxtPlugin(async (_) => {
	const authStore = useAuthStore();
	const userStore = useUserStore();
	const eventStore = useEventStore();
	const utilsStore = useUtilsDataStore();
	const articleStore = useArticleStore();
	const shopStore = useShopStore();

	await authStore.initApp();
	await userStore.fetchUser();

	// if (userStore.error != null && userStore.error != '') {
	// 	const toast = useToast();
	// 	toast.add({
	// 		title: userStore.error,
	// 	});
	// }

	await eventStore.getEvents();
	await utilsStore.fetchCountryList();
	await utilsStore.fetchStateList();
	await articleStore.getKnowledgeCategories();
	await shopStore.getNearbyShops();

	const { location } = storeToRefs(userStore);
	watch(location, (newLocation) => {
		// when location changes will triggers
		if (newLocation) {
			eventStore.getEvents();
		}
	});
	await userStore.getUserLocation();

	if (import.meta.client) {
		const stagingStore = useStagingStore();
		const { requiredPassword, isAuthenticated } = storeToRefs(stagingStore);

		if (requiredPassword.value && !isAuthenticated.value) {
			const resp = window.prompt('Password');

			if (resp == 'staging999') {
				stagingStore.setAuthenticated(true);
				return navigateTo('/');
			} else {
				return navigateTo('https://www.wemotoo.com/', { external: true, replace: true });
			}
		}
	}
});
